import { render, staticRenderFns } from "./addCrmAppModel.vue?vue&type=template&id=bc5e3602&scoped=true&"
import script from "./addCrmAppModel.vue?vue&type=script&lang=js&"
export * from "./addCrmAppModel.vue?vue&type=script&lang=js&"
import style0 from "./addCrmAppModel.vue?vue&type=style&index=0&id=bc5e3602&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc5e3602",
  null
  
)

export default component.exports